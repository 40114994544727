<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="titleTable"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :apiDeleteName="apiDelete"
  />
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_ENTITY } from "../../../../constant";
export default {
  name:"TablePageEntity",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_ENTITY,
      apiName: API_METHODS.GET_ALL_ENTITY,
      apiDelete: API_METHODS.DELETE_ENTITY
    };
  },
  computed: {
    paramsGetData() {
      const nameEntity = this.$route.params.entity;
      return { entity: nameEntity };
    },
    nameRoleEntity() {
      let res = null;
      const nameEntity = this.$route.params.entity;
      const listLoadEntity = this.$store.state.entity.listLoadEntity;
      let find = listLoadEntity.find(el => {
        let _find = el.list.find(_el => _el.code == nameEntity);
        return _find;
      });
      res = find && find.parent ? find.parent.code : nameEntity;
      const userRoleData = this.$store.state.common.userRoleData;
      const name = LIST_EQUIVALENT[res] ? LIST_EQUIVALENT[res] : res;
      if (userRoleData[name]) {
        return name;
      } else {
        return NAME_OBJECT.Reference;
      }
    },
    titleTable() {
      let entity = this.$route.params.entity;
      let listLoadEntity = this.$store.state.entity.listLoadEntity;
      let res = entity;
      if (listLoadEntity && listLoadEntity.length > 0) {
        let find = null;
        listLoadEntity.forEach(el => {
          if (find == null) {
            if (el.list && el.list.length > 0) {
              find = el.list.find(el => el.code == entity);
            }
          }
        });
        res = find ? find.name : res;
      }
      return res;
    }
  }
};
</script>
